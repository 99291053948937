<template>
  <popup :width="width" :heightPopup="height" :closePopupEvent="closePopupCB">
    <div slot="header">
      <h6 class="login-header">Change your password</h6>
    </div>
    <div class="vlt-container">
      <div class="change-details">
        <div class="vlt-input-group input-ico">
          <label>Old password</label>
          <div class="vlt-input">
            <input
              id="old-password"
              type="password"
              aria-label="old password"
              v-model="oldPassword"
              placeholder="Please enter your old password"
              autocomplete="off"
              maxlength="16"
              tabindex="2"
              :class="isBackgroundOldPass ? 'change-input-focused':''"
              @focusin="backgroundColor('isBackgroundOldPass', true)"
              @focusout="backgroundColor('isBackgroundOldPass', false)"
            />
            <span class="ico-img" style="cursor:pointer;">
              <img :src="iconEyeOpenPath" @click="toggleInputMode" alt="eye img" loading="lazy" />
            </span>
          </div>
           <p class="login-error-message">{{ oldPasswordError }}</p>
        </div>
        <div class="vlt-input-group input-ico">
          <label>New password</label>
          <div class="vlt-input">
            <input
              id="new-password"
              type="password"
              aria-label="new-password"
              v-model="newPassword"
              placeholder="Please enter your new password"
              autocomplete="off"
              maxlength="16"
              tabindex="2"
              :class="isBackgroundNewPass ? 'change-input-focused':''"
              @focusin="backgroundColor('isBackgroundNewPass', true)"
              @focusout="backgroundColor('isBackgroundNewPass', false)"
            />
            <span class="ico-img" style="cursor:pointer;">
              <img :src="iconEyeOpenPath" @click="toggleInputMode" alt="eye img" loading="lazy" />
            </span>
          </div>
          <p class="login-error-message">{{ newPasswordError }}</p>
        </div>
        <app-loader v-show="showLoading"></app-loader>

        <div class="change-btn">
          <button type="button" class="btn-login" aria-label="Change" @click="actChange">CHANGE</button>
        </div>
      </div>
    </div>
  </popup>
</template>

<script>
import { EventBus } from "@/main";
import { mapActions, mapGetters, mapMutations } from "vuex";
import SimpleCrypto from "simple-crypto-js";
import appMixins from "@/mixins/appMixins";

export default {
  data() {
    return {
      width: "45%",
      height: "60%",
      isBackgroundOldPass: false,
      isBackgroundNewPass: false,
      iconEyeOpenPath: require("@/assets/icons/eye_open.svg"),
      iconEyeClosePath: require("@/assets/icons/eye_closed.svg"),
      newPasswordError: "",
      oldPasswordError: "",
      newPassword: "",
      oldPassword: "",
      error: "",
      localLogin: "",
      showLoading: false,
      flagAutologin: true
    };
  },
  props: ["closePopupCB", "subscriberid"],
  computed: {
    ...mapGetters(["SECRET_KEY"])
  },
  methods: {
    ...mapActions(["actProfileUpdate"]),
    backgroundColor(variable, state) {
      this[`${variable}`] = state;
      if (variable == "isBackgroundOldPass") {
        this.oldPasswordError = "";
      } else {
        this.newPasswordError = "";
      }
    },
    toggleInputMode(_event) {
      let target = _event.target;
      let parentNode = target.parentNode.parentNode;
      let inputElm = parentNode.getElementsByTagName("input")[0];
      if (inputElm.type === "password") {
        inputElm.type = "text";
        target.src = this.iconEyeClosePath;
      } else {
        inputElm.type = "password";
        target.src = this.iconEyeOpenPath;
      }
    },
    actChange() {
      let localLogin = JSON.parse(localStorage.getItem("loginType"))
        ? JSON.parse(localStorage.getItem("loginType"))
        : null;

      let simpleCrypto = new SimpleCrypto(this.SECRET_KEY);

      let tempPassDec = simpleCrypto.decrypt(localLogin.password);

      if (!this.oldPassword) {
        this.oldPasswordError = "Please enter your old password";
        return;
      } else if (this.oldPassword != tempPassDec) {
        this.oldPasswordError = "Old password doesnot match";
        return;
      }

      let oldPasswordTemp = this.validatePassword(this.oldPassword);
      if (!oldPasswordTemp) {
        this.oldPasswordError = this.error;
        return;
      }

      if (!this.newPassword) {
        this.newPasswordError = "Please enter your new password";
        return;
      }

      let newPasswordTemp = this.validatePassword(this.newPassword);
      if (!newPasswordTemp) {
        this.newPasswordError = this.error;
        return;
      }

      if (this.oldPassword == this.newPassword) {
        this.newPasswordError =
          "New password cannot be same as the old password";
        return;
      }

      let param = {};
      param.subscriberid = this.subscriberid;
      param.password = this.newPassword;
      this.showLoading = true;
      this.actProfileUpdate(param)
        .then(response => {
          this.showLoading = false;
          if (!response.error) {
            let simpleCrypto = new SimpleCrypto(this.SECRET_KEY);
            let tempPassword = simpleCrypto.encrypt(this.newPassword);

            localLogin.password = tempPassword;
            localStorage.setItem("loginType", JSON.stringify(localLogin));

            this.closePopupCB();
          } else {
            this.newPasswordError = response.statusText;
          }
        })
        .catch(error => {
          this.showLoading = false;
          console.log(error);
          this.newPasswordError = error;
          if (error.status === 401) {
            // this.unauthorisedHandler();
            if (this.flagAutologin) {
              this.commonAutoLogin(this.actChange);
              this.flagAutologin = false;
              return;
            }
          }
        });
    },
    validatePassword(password) {
      let minChar = 8;
      let maxChar = 16;

      if (password.length < minChar || password.length > maxChar) {
        this.error =
          this.appMessages && this.appMessages.invalidPassword
            ? this.appMessages.invalidPassword
            : `Password should be ${minChar} - ${maxChar} characters long`;
        return false;
      } else {
        this.error = "";
        return true;
      }
    }
  },
  components: {
    popup: () =>
      import(/* webpackChunkName: "Popup" */ "@/components/Shared/popup.vue"),
    "app-loader": () =>
      import(
        /* webpackChunkName: "appLoader" */ "@/components/Shared/templates/appLoader.vue"
      )
  },
  mixins: [appMixins]

};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";
@import "~sass/modules/_hovereffects.scss";
@import "~sass/modules/_mediaQueries.scss";

.vlt-container {
  font-family: $font-family;
  font-weight: $font-weight-regular;

  .change-details {
    padding: 5% 20% 3%;
    .change-input {
      border: 1px solid $clr-bg-gray-light-4;
      border-radius: 2px;
      width: 100%;
      height: 30px;
      padding: 3%;
      margin-top: 2%;
      color: $font-clr-white;
      caret-color: $font-clr-white;
    }
    .change-input-normal {
      background: $clr-bg-gray-light-3 0% 0% no-repeat padding-box;
    }
    .change-input-focused {
      background: $clr-bg-gray-dark-1 0% 0% no-repeat padding-box;
    }
    p {
      font-family: $font-family;
      font-weight: $font-weight-medium;
      font-size: 1rem;
      margin-top: 5%;
    }
    label {
      font-family: $font-family;
      font-weight: $font-weight-medium;
      font-size:1rem;
      margin-top: 0%;
      margin-bottom: 0%;
    }
    input {
      margin-top: 2%;
      // padding: 2%;
    }
    .vlt-input>input{
       @include inputFocus;
    }
    .change-btn {
      margin: 10% 0%;
    }
    // .ico-img {
    //   top: 15px;
    // }
    .change-input-focused {
      background: $clr-bg-gray-dark-1 0% 0% no-repeat padding-box;
    }
  }
}

@include breakpoint(max480){
  .vlt-container .change-details {
    padding: 5% 10% 3%;
  }
}
</style>