<template>
  <popup :width="width" :heightPopup="height" :closePopupEvent="closePopupCB">
    <div slot="header">
      <h6 class="login-header"></h6>
    </div>
    <div class="vlt-container">
      <div class="change-details">
        <div class="vlt-input-group input-ico">
          <p>Are you sure to cancel your</p>
          <p>existing plan?</p>
        </div>
        <div class="change-btn">
          <button
            type="button"
            class="btn-login no-btn"
            aria-label="Change"
            @click="actFunctionCB(false)"
          >NO</button>
          <button
            type="button"
            class="btn-login"
            aria-label="Change"
            @click="actFunctionCB(true)"
          >YES</button>
        </div>
        <app-loader v-show="showLoading"></app-loader>
      </div>
    </div>
  </popup>
</template>

<script>
import { EventBus } from "@/main";
import { mapActions, mapGetters, mapMutations } from "vuex";
// import SimpleCrypto from "simple-crypto-js";

export default {
  data() {
    return {
      width: "30%",
      height: "35%",
      isBackgroundOldPass: false,
      isBackgroundNewPass: false,
      iconEyeOpenPath: require("@/assets/icons/eye_open.svg"),
      iconEyeClosePath: require("@/assets/icons/eye_closed.svg"),
      newPasswordError: "",
      oldPasswordError: "",
      newPassword: "",
      oldPassword: "",
      error: "",
      localLogin: "",
      showLoading: false
    };
  },
  props: ["closePopupCB", "userplanid"],
  methods: {
    actFunctionCB(val) {
      // console.log("val ", val, this.userplanid);
      if (val) {
        let data = {};
        data.status = true;
        data.userplanid = this.userplanid;
        EventBus.$emit("confirmedPopup", data);
      }
      this.closePopupCB();
    }
  },
  components: {
    popup: () =>
      import(/* webpackChunkName: "Popup" */ "@/components/Shared/popup.vue"),
    "app-loader": () =>
      import(
        /* webpackChunkName: "appLoader" */ "@/components/Shared/templates/appLoader.vue"
      )
  }
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";

.vlt-container {
  font-family: $font-family;
  font-weight: $font-weight-regular;

  .change-details {
    padding: 10% 7% 0;
    text-align: center;
    .change-input {
      border: 1px solid $clr-bg-gray-light-4;
      border-radius: 2px;
      width: 100%;
      height: 30px;
      padding: 3%;
      margin-top: 2%;
      color: $font-clr-white;
      caret-color: $font-clr-white;
    }
    .change-input-normal {
      background: $clr-bg-gray-light-3 0% 0% no-repeat padding-box;
    }
    .change-input-focused {
      background: $clr-bg-gray-dark-1 0% 0% no-repeat padding-box;
    }
    p {
      font-family: $font-family;
      font-weight: $font-weight-medium;
      font-size: 14px;
      //   margin-top: 5%;
    }
    label {
      font-family: $font-family;
      font-weight: $font-weight-medium;
      margin-top: 0%;
      margin-bottom: 0%;
    }
    input {
      margin-top: 2%;
      padding: 2%;
    }
    .change-btn {
      margin: 10% 0%;
      .btn-login {
        font-family: $font-family;
        font-weight: $font-weight-bold;
        padding: 5px;
        font-size: 14px;
        width: 23%;
        font-weight: 600;
        cursor: pointer;
      }
      .no-btn {
        margin-right: 5%;
        background-color: $clr-color-grey-1;
      }
    }
    .ico-img {
      top: 15px;
    }
    .change-input-focused {
      background: $clr-bg-gray-dark-1 0% 0% no-repeat padding-box;
    }
  }
}
</style>